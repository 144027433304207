import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "../scss/blog.module.scss"
import Seo from "../components/SEO"

export default ({ data }) => {
  console.log(data)

  return (
    <Layout>
      <Seo />
      <div>
      <iframe title="BBSBB" width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/500484186&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> 
      </div>
      <h4 className={styles.feature}>
        {data.allMarkdownRemark.totalCount} Featured Posts
      </h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (  
        <div className={styles.item}>
          <div className={styles.title}>
            <h2>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </h2>
            <p>{node.frontmatter.description}</p>
          </div>
          <div className={styles.content}>
              <div className={styles.meta}>
                <Link to={node.fields.slug}>
                  <button className="btn">Read Article</button>
                </Link>
                <h4>{node.frontmatter.date}</h4>
              </div>
             </div>
          </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YY")
            description
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
